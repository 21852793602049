import { useState } from "react";
import "../App.css";
import Canvas from "./Canvas";
import { Link } from "react-scroll";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
export default function IntroSection() {
  var w = window.innerWidth;
  var h = window.innerHeight;
  var frameWidth = 400;
  var frameHeight = 720;
  if (h > w) {
    frameWidth = window.innerWidth * 0.8;
    frameHeight = frameWidth * 1.8;
  }
  const [frameVisible, setFrameVisible] = useState(false);
  return (
    <>
      <Canvas divId="introStars"></Canvas>
      <div className="headRectangle animate__animated animate__fadeInUp">
        <div
          className="row"
          style={{
            flexDirection: h > w ? "column-reverse" : "",
          }}
        >
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-4 headFirst"
            style={{
              paddingTop: h > w ? 100 : "",
            }}
          >
            <div
              className="div-green"
              style={{
                height: frameHeight + (h > w ? 100 : 20),
                width: frameWidth + 4,
                textAlign: "left",
                padding: 0,
                overflow: "hidden",
                display: frameVisible ? "" : "none",
              }}
            >
              <iframe
                style={{
                  width: frameWidth,
                  height: frameHeight,
                }}
                title="Flooz <> Emorya Finance"
                frameborder="0"
                allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *"
                src="https://flooz.xyz/embed/trade?swapDisabled=true&swapNetwork=eth&swapLockToToken=false&onRampDisabled=false&onRampNetwork=arbitrum&onRampAsDefault=false&onRampTokenAddress=0x7dbED01465B5038bE1212705F82eb4244583Ec85&onRampLockToken=true&network=eth&lightMode=false&backgroundColor=transparent&miniApp=false&miniappIntent=swap"
              ></iframe>
              <div
                className="btn-green-wrapper"
                style={{
                  zIndex: 2000,
                  position: "absolute",
                  bottom: 40,
                  left: 20,
                  width: "90%",
                }}
                onClick={() => setFrameVisible(false)}
              >
                <div className="btn-green">
                  <i className="fas fa-arrow-left"></i>&nbsp;&nbsp;Go Back
                </div>
              </div>
              <div className="div-blur"></div>
              <br />
              <br />
            </div>
            <div
              className="div-green"
              style={{
                height: frameHeight + 20,
                width: frameWidth + 4,
                textAlign: "left",
                display: frameVisible ? "none" : "",
              }}
            >
              <div className="div-blur"></div>
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "normal",
                  textTransform: "capitalize",
                }}
              >
                <br />
                Buy EMR from your
                <br />
                <span style={{ color: "#1ad182", fontWeight: "bold" }}>
                  Credit card
                </span>{" "}
                right now!
              </div>
              <br />
              <br />
              <img
                src={require("../assets/credit-card.png")}
                style={{
                  width: "80%",
                }}
                alt=""
              ></img>
              <br />
              <br />
              <div
                className="btn-green-wrapper"
                onClick={() => setFrameVisible(true)}
              >
                <div className="btn-green">
                  Click To Buy Now&nbsp;&nbsp;
                  <i className="fas fa-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-7 headSecond"
            style={{
              paddingTop: 50,
            }}
          >
            <div className="green-heading">
              Value Redefined
              <br />
              To Infinity & Beyond!
            </div>
            <div className="text">
              Emorya $EMR is a Hyper Deflationary Token created on{" "}
              <span className="green-color">MultiversX Blockchain</span> and it
              is the first project of this kind on the global scale that aims to
              offer you the opportunity to earn rewards depending on the
              calories you burn.
            </div>
            <div
              className="btn-green-wrapper"
              style={{
                marginTop: 10,
              }}
            >
              <Link to="tokenDistribution" smooth={true}>
                <button className="btn-green">TOKEN DISTRIBUTION</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
